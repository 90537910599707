import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './index.css'

import * as Sentry from "@sentry/vue";

import VueCookies from 'vue-cookies'
import Notifications from '@kyvg/vue3-notification'

import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const pinia = createPinia()
const app = createApp(App);

import { useContextStore } from "@/stores/context";

import { useFarmerStore } from "@/stores/farmer";
import { useVillageStore } from '@/stores/village'
import { useDealerStore } from '@/stores/dealer'
import { useHybridStore } from '@/stores/hybrid'
import { useWorkLocationStore } from '@/stores/workLocation'
// import { useClockInStore } from '@/stores/clockin'
// import { useClockOutStore } from '@/stores/clockout'
import { useStaffStore } from '@/stores/staff'
import { useCompetitorStore } from '@/stores/competitor'

import { useFileStore } from '@/stores/file'

import { VueFire, VueFireFirestoreOptionsAPI, VueFireDatabaseOptionsAPI } from 'vuefire'
import { firebaseApp } from "@/firebase";

import VersionCheck from '@/mixins/VersionCheck';
import UpdateApp from '@/mixins/update';
import { utils } from './utilities'

app.use(router)
  .use(Notifications)
  .use(VueCookies, { expires: '30d' })
  .use(pinia)
  .use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    modules: [
      // to use the `firestore` option
      VueFireFirestoreOptionsAPI(),
      // to use the `firebase` option
      VueFireDatabaseOptionsAPI(),
    ],
  })
  // .mixin(VersionCheck)
  .mixin(UpdateApp)
  .use(LoadingPlugin);

// app.config.globalProperties.user = ''
if (utils.isDev() === false) {
  Sentry.init({
    app,
    // dsn: "https://a14016a9f612f9786334ca7e15f30ec0@o4505383912865792.ingest.us.sentry.io/4507366711099392",
    dsn: process.env.VUE_SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://lim-pwa.testzoo.dev"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.mount('#app');

// Register the service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}

const contextStore = useContextStore();

const farmerStore = useFarmerStore();
const villageStore = useVillageStore();
const dealerStore = useDealerStore();
const hybridStore = useHybridStore();
const workLocationStore = useWorkLocationStore();
// const clockinStore = useClockInStore();
// const clockoutStore = useClockOutStore();
const staffStore = useStaffStore();
const competitorStore = useCompetitorStore();

const fileStore = useFileStore();

const initDatabase = async () => {
  return new Promise((resolve, reject) => {
    let db = window.indexedDB.open("limagrain_db", process.env.VUE_APP_INDEXED_DB_VERSION);

    db.onerror = () => {
      reject('Error opening the database.');
    };

    db.onsuccess = (e) => {
      farmerStore.updateDatabase(e.target.result);
      villageStore.updateDatabase(e.target.result);
      dealerStore.updateDatabase(e.target.result);
      hybridStore.updateDatabase(e.target.result);
      workLocationStore.updateDatabase(e.target.result);
      // clockinStore.updateDatabase(e.target.result);
      // clockoutStore.updateDatabase(e.target.result);
      staffStore.updateDatabase(e.target.result);
      competitorStore.updateDatabase(e.target.result);
      fileStore.updateDatabase(e.target.result);
      resolve('OK');
    };

    db.onupgradeneeded = (e) => {
      if (e.oldVersion === 17) {
        e.target.result.deleteObjectStore("activities");
        e.target.result.deleteObjectStore("farmers");
        e.target.result.deleteObjectStore("villages");
        e.target.result.deleteObjectStore("dealers");
        e.target.result.deleteObjectStore("hybrids");
        e.target.result.deleteObjectStore("workLocations");
        e.target.result.deleteObjectStore("clockIns");
        e.target.result.deleteObjectStore("clockOuts");
        e.target.result.deleteObjectStore("staffs");
        e.target.result.deleteObjectStore("competitors");
      }

      // e.target.result.createObjectStore("activities", { keyPath: "id" });
      e.target.result.createObjectStore("farmers", { keyPath: "id" });
      e.target.result.createObjectStore("villages", { keyPath: "id" });
      e.target.result.createObjectStore("dealers", { keyPath: "id" });
      e.target.result.createObjectStore("hybrids", { keyPath: "id" });
      e.target.result.createObjectStore("workLocations", { keyPath: "id" });
      // e.target.result.createObjectStore("clockIns", { keyPath: "date" });
      // e.target.result.createObjectStore("clockOuts", { keyPath: "date" });
      e.target.result.createObjectStore("staffs", { keyPath: "id" });
      e.target.result.createObjectStore("competitors", { keyPath: "id" });

      var objectStore = e.target.result.createObjectStore('files', { keyPath: 'id' });
      // Define the structure of the data to be stored
      objectStore.createIndex('name', 'name', { unique: false });
      objectStore.createIndex('type', 'type', { unique: false });
      objectStore.createIndex('data', 'data', { unique: false });
    };
  });
}

initDatabase();

contextStore.init();

farmerStore.initFarmers();
villageStore.initVillages();
dealerStore.initDealers();
hybridStore.initHybrids();
workLocationStore.initWorkLocations();
// clockinStore.initClockIns();
// clockoutStore.initClockOuts();
staffStore.initStaffs();