<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import Label from "@/components/Label";
import VueSelect from "vue-select";
import { api } from "@/services/api";
import { utils } from "@/utilities.js";

import { useDealerStore } from "@/stores/dealer";
import { useHybridStore } from "@/stores/hybrid";
import { useContextStore } from "@/stores/context";

const contextStore = useContextStore();

const dealerStore = useDealerStore();
const hybridStore = useHybridStore();

const dealerSelected = ref("");
const dealerReady = ref(false);
let dealersOptions = reactive([]);

const hybridReady = ref(false);
let hybridsOptions = reactive([]);

const props = defineProps(["myLocation", "activity"]);
const emits = defineEmits(["submitMaster"]);

const form = reactive({
  activity_type: "Spot Sale",
  date: utils.getCurrentDate(),
  dealer_id: props.activity.schedulable_id ?? "",
  sold_hybrids: [],
  feedback: "",
  budget_spent: props.activity?.budget ? parseFloat(props.activity.budget) : 0,
  attendance: 0,
  description: "",
  files: "",

  check_out: true,
  sale_schedule: props.activity,

  latitude: props.myLocation.latitude,
  longitude: props.myLocation.longitude,
  current_latitude: props.myLocation.current_latitude,
  current_longitude: props.myLocation.current_longitude,
  distance: utils.getDistanceFromActivity(props.activity),

  isLaterActive: true,
});

onMounted(async () => {
  await dealerStore.initDealers();
  dealersOptions = dealerStore.getDealers();

  if (dealersOptions.length == 0 || contextStore.getIsOffline == false) {
    dealersOptions = await api.getDealers();
    dealersOptions.forEach((data) => {
      dealerStore.addNewDealer(data);
    });
  }

  await hybridStore.initHybrids();
  hybridsOptions = hybridStore.getHybrids();

  if (hybridsOptions.length == 0 || contextStore.getIsOffline == false) {
    hybridsOptions = await api.getHybrids();
    hybridsOptions.forEach((data) => {
      hybridStore.addNewHybrid(data);
    });
  }

  dealersOptions = await utils.convertToSelectObject(dealersOptions);
  hybridsOptions = await utils.convertToSelectObject(hybridsOptions);
  dealerReady.value = true;
  hybridReady.value = true;

  hybridsOptions.forEach((e) => {
    form.sold_hybrids.push({
      hybrid_id: e.value,
      name: e.label,
      sold: "",
    });
  });
});

watch(
  () => dealerReady.value,
  (n, o) => {
    if (form.dealer_id && n) {
      const d = dealersOptions.filter((e) => e.value == form.dealer_id);
      dealerSelected.value = d[0] ?? {};
    }
  }
);

const submit = async () => {
  form.dealer_id = dealerSelected?.value?.value;

  emits("submitMaster", form);
  return;
};
</script>
<template>
  <form class="space-y-4 text-left" action="#" @submit.prevent="submit">
    <div class="grid grid-cols-6 gap-6">
      <div class="col-span-6 sm:col-span-2">
        <Label value="Distance (Km)" />
        <input
          class="mt-1 block w-full rounded-lg resize-y"
          type="text"
          v-model="form.distance"
        />
      </div>

      <div class="col-span-6 sm:col-span-2">
        <Label for="date" value="Date" />
        <input
          id="date"
          type="date"
          :max="utils.getCurrentDate()"
          class="mt-1 block w-full rounded-lg"
          v-model="form.date"
          disabled
        />
      </div>
      <div class="col-span-6 sm:col-span-2">
        <Label for="activity_type" value="Activity Type" />
        <input
          id="activity_type"
          type="text"
          v-model="form.activity_type"
          class="mt-1 block w-full rounded-lg"
          disabled
        />
      </div>

      <div class="col-span-6 sm:col-span-6">
        <Label for="dealer_id" value="Dealer / SubDealer" class="required" />
        <VueSelect
          v-if="dealerReady"
          v-model="dealerSelected"
          :options="dealersOptions"
          label="label"
          value="value"
          placeholder="Select"
          :searchable="true"
          class="block w-full my-1 bg-white rounded-lg"
        >
          <template #search="{ attributes, events }">
            <input
              class="vs__search"
              :required="!dealerSelected"
              v-bind="attributes"
              v-on="events"
            />
          </template>
        </VueSelect>
      </div>
      <div class="col-span-6 sm:col-span-6">
        <Label for="sold_hybrids" value="Hybrid - Sold" />

        <div
          class="grid grid-cols-2 xl:grid-cols-4 gap-4 p-3 bg-blue-100 rounded"
        >
          <div v-for="hybrid in form.sold_hybrids" :key="hybrid.id">
            <div class="">
              <span class="text-sm inline-block text-right"
                >{{ hybrid.name }}(bags)</span
              >
              <input
                type="number"
                class="mt-1 block w-full text-sm px-2 py-1 rounded-lg"
                v-model="hybrid.sold"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                @focus="utils.onFocusDefaultVal($event)"
                maxlength="5"
                min="0"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-6 sm:col-span-6">
        <Label for="description" value="Description" class="required" />
        <textarea
          name=""
          id="description"
          cols="30"
          rows="5"
          class="mt-1 w-full rounded-lg"
          v-model="form.description"
          required
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <Label for="feedback" value="Feedback" />
        <input
          id="feedback"
          type="text"
          class="mt-1 block w-full rounded-lg"
          v-model="form.feedback"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <Label for="attendance" value="Attendance" class="required" />
        <input
          id="attendance"
          type="number"
          class="mt-1 block w-full rounded-lg"
          v-model="form.attendance"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          @focus="utils.onFocusDefaultVal($event)"
          maxlength="5"
          min="0"
          required
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <Label for="start_date" value="Upload Media" />
        <input
          id="files"
          type="file"
          class="mt-1 block w-full rounded-lg shadow-sm"
          @input="form.files = Array.from($event.target.files)"
          multiple
        />
        <p class="text-sm text-gray-500 mt-2">max size per file: 10mb</p>
        <!-- <template v-for="(f, index) in form.files" :key="f.file">
        <input-error
          :message="form.errors?.[`files.${index}`]"
          class="mt-2"
          v-if="form.errors?.[`files.${index}`]"
        />
      </template> -->
      </div>
      <div class="col-span-6 sm:col-span-3">
        <Label for="budget_spent" value="Budget Spend" class="required" />
        <input
          id="budget_spent"
          type="number"
          class="mt-1 block w-full rounded-lg"
          v-model="form.budget_spent"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          @focus="utils.onFocusDefaultVal($event)"
          maxlength="7"
          min="0"
          required
        />
      </div>

      <div class="flex justify-end col-span-6">
        <button
          type="submit"
          class="inline-flex items-center px-4 py-2 border border-transparent rounded-lg font-semibold text-xs tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition ml-2 bg-green-500 text-white"
        >
          Check Out
        </button>
      </div>
    </div>
  </form>
</template>
