<script setup>
import { onMounted, ref, watch } from "vue";
import { api } from "@/services/api";
import { utils } from "@/utilities";
import { useLoading } from "vue-loading-overlay";
import { notify } from "@kyvg/vue3-notification";
import AppLayout from "@/layouts/AppLayout.vue";
import AddUnPlan from "@/components/Modal/AddUnPlan.vue";
import CheckIn from "@/components/Modal/CheckIn.vue";

import { useRoute, useRouter } from "vue-router";

import ActivityFS from "@/firebases/ActivityFS";

import { useContextStore } from "@/stores/context";
import ClockInOutFS from "@/firebases/ClockInOutFS";
const contextStore = useContextStore();

const loadCompleted = ref(false);

var activities = ref([]);

const alreadyClockIn = ref(false);
const alreadyClockOut = ref(false);
const todayClockInOut = ref(null);
const canClockOut = ref(true);

const $loading = useLoading({});

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  await fetchClockInOut();
  await fetchActivities();

  loadCompleted.value = true;
});

const fetchClockInOut = async () => {
  if (contextStore.getIsOffline === false) {
    const response = await api.getTodayClockInOut();
    if (response.clockIn) {
      ClockInOutFS.addClockInToday(response.clockIn)
      alreadyClockIn.value = true;
    }
    if (response.clockOut) {
      ClockInOutFS.addClockOutToday(response.clockOut)
      alreadyClockOut.value = true;
    }
  } else {
    todayClockInOut.value = await ClockInOutFS.getTodayClockInOut();
    if (todayClockInOut.value) {
      if (todayClockInOut.value?.clockIn && todayClockInOut.value.clockIn) {
        alreadyClockIn.value = true;
      }

      if ((todayClockInOut.value?.clockOut && todayClockInOut.value.clockOut) || route.query?.isClockOut) {
        router.push({ query: {} });
        alreadyClockOut.value = true;
      }
    }
  }
}

const fetchActivities = async () => {
  activities.value = [];
  if (contextStore.getIsOffline === false) {
    let loader = $loading.show({});

    const response = await api.getActivitiesToday(utils.getCurrentDate());
    if (response.length) {
      for (const res of response) {
        await ActivityFS.createOrUpdateActivity(res);
      }
    }

    loader.hide();
  }

  activities.value = await ActivityFS.getActivities(utils.getCurrentDate());
  activities.value = utils.sortArrayActivity(activities.value);
  activities.value.forEach((item) => {
    if (item.sales_checkout == null) {
      canClockOut.value = false;
      return;
    }
  })
};

const checkedSuccess = (v) => {
  if (v) {
    loadCompleted.value = false; // Trigger change

    setTimeout(async () => {
      await fetchActivities();
      loadCompleted.value = true;
    }, 1000);
  }
};

const reloadPage = () => {
  if (contextStore.getIsOffline === false) {
    location.reload(true);
  } else {
    history.go()
  }
}

const canClockOutNotice = () => {
  notify({
    group: "bottom-center-group",
    type: "error",
    title: "Please complete all today activities before clock out!",
  });
  return true;
}

const canAddUnPlan = () => {
  return alreadyClockIn.value === true && alreadyClockOut.value === false;
}

watch(
  () => contextStore.getIsOffline,
  async (n, o) => {
    // Online and not first time load
    if (n === false && o != undefined) {
      checkedSuccess(true);
    }
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <AppLayout>
    <div class="relative overflow-x-auto my-6">
      <div class="text-lg font-bold my-3 flex justify-start gap-2">
        <span>Today Activity</span>
        <button type="button" class="text-sm bg-yellow-400 p-1" @click.prevent="reloadPage()">Refresh</button>
      </div>
      <table class="w-full text-sm text-left rtl:text-right">
        <thead>
          <tr>
            <th colspan="4">
              <router-link to="/clock-in" class="">
                <button
                  type="button"
                  :disabled="alreadyClockIn"
                  class="w-full block disabled:cursor-not-allowed disabled:opacity-25 py-4 text-base bg-gray-800 text-white"
                >
                  <span class="text-center mx-auto" v-if="!alreadyClockIn"
                    >Clock - In : with {{ utils.getCurrentTime() }}</span
                  >
                  <span class="text-center mx-auto" v-else
                    >Clock - In Completed
                  </span>
                </button>
              </router-link>
            </th>
          </tr>
        </thead>
        <tbody v-if="loadCompleted">
          <tr
            class="bg-white border-b"
            v-for="(activity, index) in activities"
            :key="index"
          >
            <th
              scope="row"
              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center"
            >
              {{ index + 1 }}
            </th>
            <td class="px-6 py-4">
              <CheckIn
                :activityId="activity?.id"
                :disabled="activity?.sales_checkin"
                :salesRouteLineId="activity?.sales_route_lines?.id"
                @checked="checkedSuccess"
                :alreadyClockIn="alreadyClockIn"
              />
            </td>
            <td class="px-6 py-4 text-center font-bold text-lg">
              {{ utils.activityStr(activity?.activity_type) }}
              <span class="italic">{{ activity?.remark }}</span> ที่
              <span class="italic">{{ activity?.schedulable?.name }}</span>
              [<span
                class="text-green-600 text-sm"
                v-if="activity?.id && activity.id && !isNaN(activity?.id)"
                >Synced</span
              ><span class="text-gray-600 text-sm" v-else>Unsync</span>] 
              <span v-show="utils.isDev()">[{{activity?.id}}]</span>
            </td>
            <td class="px-6 py-4">
              <router-link
                :to="`/check-out/` + activity?.id"
                class="flex items-center"
              >
                <button
                  type="button"
                  class="bg-gray-800 px-4 py-2 text-white rounded-lg disabled:opacity-25 disabled:cursor-not-allowed whitespace-nowrap w-full"
                  :disabled="
                    !activity?.sales_checkin || activity?.sales_checkout
                  "
                >
                  <span v-if="activity?.sales_checkout?.id < 0">Checked</span>
                  <span v-else>Check Out</span>
                </button>
              </router-link>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr v-if="canAddUnPlan()">
            <th colspan="4" class="p-2">
              <AddUnPlan v-if="loadCompleted" @checked="checkedSuccess" />
            </th>
          </tr>
          <tr v-if="alreadyClockIn">
            <th colspan="4">
              <button
                  v-if="canClockOut === false"
                  type="button"
                  @click.prevent="canClockOutNotice"
                  class="w-full block disabled:cursor-not-allowed disabled:opacity-25 py-4 text-base bg-gray-800 text-white"
                >
                  <span class="text-center mx-auto">Clock - Out</span>
              </button>

              <router-link to="/clock-out" class="" v-else>
                <button
                  :disabled="alreadyClockOut"
                  type="button"
                  class="w-full block disabled:cursor-not-allowed disabled:opacity-25 py-4 text-base bg-gray-800 text-white"
                >
                  <span class="text-center mx-auto" v-if="alreadyClockOut"
                    >Clock - Out Completed</span
                  >
                  <span class="text-center mx-auto" v-else>Clock - Out</span>
                </button>
              </router-link>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </AppLayout>
</template>
